import React from "react"
import { Link } from "gatsby"
import styles from "./postPreview.module.scss"

const PostPreview = ({ post }) => {
  console.log(post.body.fields.readingTime.minutes);
  return (
    <Link to={`/blog/${post.slug}`}>
      <h2 className={styles.postTitle}>{post.title}</h2>
      <h4 className={styles.postAbstract}>{post.abstract.abstract}</h4>
      <span className={styles.postDate}>
        <small> {post.createdAt} {' • '}</small>
        <small>{`${Math.round(post.body.fields.readingTime.minutes)} min read`}</small>
      </span>
      
      <h1>
      {post.tags.map(tag => {
        return (
          <span className={styles.postTag}>
            <small>{tag}</small>{" "}
          </span>
        )
      })}
      </h1>
    </Link>
  )
}

export default PostPreview
