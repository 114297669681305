import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./blog.module.scss"
import SEO from "../components/seo"
import PostPreview from "../components/postPreview"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            title
            createdAt(formatString: "MMMM Do, YYYY")
            slug
            abstract {
              abstract
            }
            body {
              fields {
                readingTime {
                  minutes
                }
              }
            }
            tags: tag
          }
        }
      }
    }
  `)
  return (
    <div>
      <Layout>
        <SEO title="Blog" />
        <h4>Latest blog post</h4>
        <ol className={styles.posts}>
          {data.allContentfulBlogPost.edges.map(edge => {
            return (
              <li className={styles.post}>
                <PostPreview post={edge.node} />
              </li>
            )
          })}
        </ol>
      </Layout>
    </div>
  )
}

export default BlogPage
